import React from "react";
import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";

const allBlogs = [
  {
    id: 1,
    title: "What working on Reducr has taught me about interaction design",
    image: "images/blog/angella-blog-3.png",
    filesource: "../../blogs/pham-mai-ngoc.md",
    date: "30 October, 2023",
    author: "Pham, Mai Ngoc",
    category: "Reviews",
    pfp: "/images/angella.png"
  },
  {
    id: 2,
    title: "What I learnt at each step of the design process in making Reducr",
    image: "images/blog/james-blog.jpg",
    filesource: "../../blogs/zhou-james-wang.md",
    date: "31 October, 2023",
    author: "Zhou, James Wang",
    category: "Tutorial",
    pfp: "/images/james.jpeg"
  },
  {
    id: 3,
    title: "What I learn from Reducr, and what I would do differently for future projects",
    image: "images/blog/alex-blog.jpeg",
    filesource: "../../blogs/wu-alex-yuxuan.md",
    date: "29 October, 2023",
    author: "Wu, Alex Yuxuan",
    category: "Business",
    pfp: "/images/alex.png"
  },
  {
    id: 4,
    title: "Reflecting on the design process for Reducr, from start to finish",
    image: "images/blog/gabriel-blog-2.jpg",
    filesource: "../../blogs/ting-gabriel-angelo.md",
    date: "30 October, 2023",
    author: "Ting, Gabriel Angelo",
    category: "Business",
    pfp: "/images/gabriel.jpeg"
  },
];

function Blogs() {
  return (
    <section id="blog">
      <div className="container">
        <Pagetitle title="🍌 Reflections" />
        <div className="row blog-wrapper" style={{ gridRowGap: "24px" }}>
          {allBlogs.map((blogItem) => (
            <div className="col-md-6" key={blogItem.id}>
              <Blog blogData={blogItem}/>
            </div>
          ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
