import React from "react";
import Pagetitle from "../elements/Pagetitle";

function Problem() {
  return (
    <section id="problem">
      <div className="container">
        <Pagetitle title="🥬 Problem" />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2 style={{ marginTop: "0px" }}>Context</h2>
          <p>Singapore imports more than 90% of its food from overseas, yet a staggering amount is wasted annually. In 2022, the city <mark>generated over 813,000 tonnes of food waste</mark>, with households being one of the primary contributors to this issue. When we waste food, we also squander all the resources invested in its production and transportation.</p>
          <p>This problem is highly relevant to both students and young adults living in residential settings as they have the tendency to overlook such issues. We have selected this problem because we frequently encounter it in our personal lives, resulting in <mark>significant food and financial waste due to not consuming food items by their expiry dates</mark>. Therefore, our objective is to find a solution that can benefit a broad audience, ultimately improving food distribution, sustainability, and minimising waste.</p>
          <h2>Consequences</h2>
          <p>People often squander money on items they ultimately don't consume. This not only leads to the wastage of resources used in the production and delivery of the food but also exacerbates our carbon footprint, <mark>contributing to global warming and climate change</mark>. Additionally, this can impose a substantial financial burden on households, as they make unnecessary purchases, and the <mark>pressure to consume the excess can pose health risks to individuals</mark>.</p>
          <h2>Feasibility</h2>
          <p>While there are existing apps attempting to tackle this issue, they frequently focus solely on tracking expiry dates and <mark>fail to offer additional insights on how users can effectively reduce food waste</mark> and make continuous improvements. Due to limited functionality, these apps tend to become unengaging, and end up being forgotten by users instead of becoming an integral part of their daily routine.</p>
        </div>
      </div>
    </section>
  );
}

export default Problem;
