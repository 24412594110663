import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

function Blog({ blogData }) {
  const { title, date, author, image, pfp, source } = blogData;

  const formatAuthor = (author) => {
    let newAuthor = author.replace(",", "");
    newAuthor = newAuthor.replaceAll(" ", "-");
    newAuthor = newAuthor.toLowerCase();
    return newAuthor;
  }
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      {image && <div className="blog-item rounded bg-white shadow-dark" style={{ display: "flex" }}>
        <div className="thumb" style={{ width: "50%" }}>
          <Link to={source ? `user-evaluation/${source}` : `reflections/${formatAuthor(author)}`}>
            <img src={image} alt="blog-title" style={{ objectFit: "cover", width: "100%", height: "100%" }}/>
          </Link>
        </div>
        <div className="details" style={{ width: "50%", boxSizing: "border-box", gap: "10px", display: "flex", flexDirection: "column" }}>
          <h4 className="my-0 title">
            <Link
              to={source ? `user-evaluation/${source}` : `reflections/${formatAuthor(author)}`}
              style={{ textDecoration: 'none' }}
            >
              {title}
            </Link>
          </h4>
          <p style={{ fontSize: "0.9rem", margin: "0" }}>{date}</p>
          <div style={{ display: "flex", gap: "8px" }}>
            <div style={{ width: "20px", height: "20px", bottom: '3px', position: 'relative' }}>
              <img src={pfp} alt="User image" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "100%" }}/>
            </div>
            <p style={{ fontSize: "0.9rem", opacity: "0.7", margin: "0", textAlign: 'left' }}>{author}</p>
          </div>
        </div>
      </div>}
      {!image && <div className="blog-item rounded bg-white shadow-dark" style={{ display: "flex", height: "100% !important" }}>
        <div className="details" style={{ width: "100%", boxSizing: "border-box", gap: "30px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <h4 className="my-0 title">
            <Link
              to={source ? `user-evaluation/${source}` : `reflections/${formatAuthor(author)}`}
              style={{ textDecoration: 'none' }}
            >
              {title}
            </Link>
          </h4>
          <div style={{ display: "flex", gap: "8px" }}>
            <p style={{ fontSize: "0.9rem", opacity: "0.7", margin: "0", textAlign: 'left' }}>{author}</p>
            <div style={{ width: "20px", height: "20px", bottom: '3px', position: 'relative' }}>
              <img src={pfp} alt="User image" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "100%" }}/>
            </div>
          </div>
        </div>
      </div>}
    </ScrollAnimation>
  );
}

export default Blog;
