import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import BlogDetails from "./pages/BlogDetails";
import Homepage2Light from "./pages/Homepage2Light";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Homepage2Light />
        </Route>
        <Route path="/user-evaluation/:user" component={BlogDetails} />
        <Route path="/reflections/:member" component={BlogDetails} />
        <Route path="/final-interactive-prototype" component={FinalInteractivePrototype} />
        <Route path="/reflective-design-notebook" component={ReflectiveDesignNoteBook} />
        <Route path="/presentation-video" component={PresentationVideo} />
      </Switch>
    </BrowserRouter>
  );
}

const FinalInteractivePrototype = () => {
  window.location.replace('https://www.figma.com/proto/LtGg4j7UhjxtFrQfo66yWj/G3---Final-Interactive-Prototype?type=design&node-id=7-423&t=hqQxlV6mLwJ1XAQq-1&scaling=scale-down&page-id=0%3A1&starting-point-node-id=7%3A423&mode=design');
}

const ReflectiveDesignNoteBook = () => {
  window.location.replace('https://www.figma.com/file/HykcZRgMBumKuQCeRTNGuQ/Reflective-Design-Notebook?type=whiteboard&node-id=0%3A1&t=UnbUZL3pmnzIHz29-1');
}

const PresentationVideo = () => {
  window.location.replace('https://youtu.be/0S0FNdWGrXw?si=UfH96krTVzxYosrg');
}

export default App;
