import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Blog from "../elements/Blog";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: '10px',
  boxShadow: '0px 5px 20px 0px rgba(69, 67, 96, 0.1)',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "28px",
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const personas = [
  {
    name: "Alice",
    image: "/images/alice.png",
    category: "college student",
    description: "Alice is a 20 year-old student who goes lives on campus, and only ever cooks for herself. She finds grocery shopping to be quite a hassle, as it is difficult to buy the right amount of products. Alice always ends up underbuying or overbuying the items she needs to get, so it becomes inconvenient when she has to make more trips to the grocery store, or wasteful when she has to trash expired products. Alice wants to find a way to conveniently keep track of the items she has in her kitchen, so she knows how much to buy at the grocery store. Overall, she wants to reduce her personal food wastes, and save money.",
    scenario: [
      "Joe lives on his own and cooks most of his dinners when he is at home. Last week, Joe’s local supermarket had a special on eggs if he purchased two cartons, so he purchased two cartons of eggs. Unfortunately, Joe had an extremely busy week at work and worked overtime almost every day, leaving him no time to cook. To further matters, Joe is about to leave for a business trip in a few days.", 
      "It is now the weekend and Joe looks through his fridge to see what food he has to finish before he leaves. He spots the two cartons of eggs, notices that the expiry date is next week, and realises that he will not be able to finish one of the cartons before the best before date. Joe is conscious about his food wastage and is determined not to let these perfectly good eggs go to waste.",
      "Joe opens the app on his smartphone, and navigates to the marketplace section. He then selects the “add” button to create a listing for the goods that he is offering. He selects the eggs from his inventory, indicates a quantity of 1 carton containing one dozen, and takes a picture of the carton of eggs. Information such as Joe’s neighbourhood and the expiration date are included on the listing based on previously existing information in the app.",
      "Meanwhile, Alice is on the app and preparing her meals for the weekend. She notices that she is short a few eggs, so she navigates to the marketplace section and searches for “eggs”. She finds Joe’s listing, noticing that he is not too far from her, and asks if he is available later that afternoon for her to pick up the eggs. Joe confirms his availability, arranges a pickup time of 2:30pm, and sends her his exact address.",
      "At 2:30pm, Alice arrives at Joe’s place and obtains the carton of eggs from him. Joe then marks the listing as picked up in the app, and the eggs are automatically removed from his inventory. Meanwhile, the eggs are automatically added to Alice’s inventory in the app. Joe ended up happy that his eggs did not end up being thrown out, and Alice was happy that she had enough eggs to make dinner that day."
    ],
    storyboard: "/images/alice-storyboard.png",
  },
  {
    name: "Bob",
    image: "/images/bob.png",
    category: "single parent",
    description: "Bob is a 34 year-old father who goes grocery shopping once to twice a week, and often cooks at home simple meals based on whatever ingredients are on hand. He finds it difficult to reduce food waste as specials for buying large quantities are quite enticing, and sometimes doesn’t know what to do with certain leftover ingredients. Bob wants to find simple recipes, efficiently keep track of everything, and reduce spending to increase cost efficiency. Overall, he wants to learn to cook ingredient efficient meals and reduce waste.",
    scenario: [
      "Bob often cooks simple meals at home and wants to reduce food waste as much as he can. He is unsure of what ingredients he has in his pantry. He opens the app and clicks onto the ‘Inventory’ section to see what he has and if he needs to update the details of any items.",
      "Looking through his pantry, he noticed that he has some milk that he bought yesterday that he hasn’t added into the app’s inventory list. He adds this in by first clicking the ‘Add Item’ button on the inventory page. Once clicked, the app prompts Bob to fill in what item he wants to add, the quantity and as well as the expiry date. After adding the milk to the app’s virtual inventory, he continues looking through his pantry to see any ingredients that he needs to update in the app.",
      "As he is browsing, the app shows a warning to Bob that some of his eggs he had are about to expire. Bob notes this down and decides to cook scrambled eggs for breakfast as he wants to avoid throwing out unused ingredients when they are about to expire to reduce food waste. He likes to add milk to his scrambled eggs so he used this ingredient in his recipe.",
      "After cooking his meal, Bob updates the remaining portions of ingredients in the app’s virtual inventory. He does this by marking that he has consumed all the remaining eggs and updates the quantity of milk he has left to take into account the milk he used to make the meal.",
      "The next day, the app sends a notification to Bob that his milk is about to expire soon and he decides to drink the remaining milk."
    ],
    storyboard: "/images/bob-storyboard.png",
  },
  {
    name: "Carol",
    image: "/images/carol.png",
    category: "cooking enthusiast",
    description: "Carol is a 22 year-old university student who goes grocery shopping twice or more a week with extra trips for specific ingredients, who often tries out new and interesting recipes that she finds. Because of the uniqueness of some dishes that she tries, despite her best effort at planning out ingredient usage some of it will often go unused past the particular dish that she makes. Carol wants to manage her ingredient usage and purchasing so that less is wasted and more money is saved, as well as potentially find substitutes for lesser-known and lesser used ingredients. Overall, she wants to reduce raw material waste.",
    scenario: [
      "Carol wants to minimise food wastage and wants to cook unique and interesting dishes for that week. She first opens the application to see what she would like to cook.",
      "On the homepage, the app first shows a list of recommended dishes based on her personal preferences. She scrolls through the list of recommended dishes on the homepage until she sees the Butter Chicken Curry dish that she would like to cook. She selects the Butter Chicken Curry meal to view the recipe. The app then displays the recipe's description and a list of ingredients and the required quantity to cook the dish. The app’s virtual pantry inventory shows the comparison between ingredient quantity required to make the dish to how much she currently has.",
      "Carol scrolls through the Butter Chicken Curry recipe taking note of the ingredients required to cook the dish. She remembers that she had some milk on hand in her pantry as she didn’t finish it last week and goes back to the homepage to update the app’s virtual inventory list. The app keeps track of this change and updates the ingredient quantity list for the Butter Chicken Curry recipe accordingly.",
      "Also, Carol wants to cook a smaller portion to better suit her diet. She then adjusts the portion size which the app then updates the ingredient quantities on the recipe list to tailor for her individual portion size preferences. After making the desired changes, Carol adds this recipe to her virtual shopping cart and then continues searching for other recipes that she would like to cook for that week.",
      "After figuring out what she wants to cook for that week, she views her shopping cart of recipes in the app and clicks the ‘Generate Shopping List’ button. The app then shows Carol a list of ingredients and the quantity to get for her shopping journey. In the supermarket, Carol shops for the ingredients specified on the app’s shopping list and ticks off each item on the app once she adds it to her shopping cart.",
    ],
    storyboard: "/images/carol-storyboard.png",
  },
]

const images = [
  {
    id: 1,
    src: "images/angella-crazy8.png",
    alt: "Pham, Mai Ngoc's crazy-8",
  },
  {
    id: 2,
    src: "images/james-crazy8.png",
    alt: "Zhou, James Wang's crazy-8",
  },
  {
    id: 3,
    src: "images/gabriel-crazy8.png",
    alt: "Ting, Gabriel Angelo's crazy-8",
  },
  {
    id: 4,
    src: "images/alex-crazy8.png",
    alt: "Wu, Alex Yuxuan's crazy-8",
  },
];

const wireframes = [
  {
    name: 'Pham, Mai Ngoc',
    img: 'images/angella.png',
    screenshots: [
      {
        task: '1',
        path: '/images/angella-wireframe-1.png',
        link: 'https://balsamiq.cloud/snnaqus/py1c4gk/r66F6',
        strengths: [
          "Able to visualise each listing on a map that gives users a guide on the nearest item",
          "User flow seems clear as you can create a listing from the main page and also view a listing from there",
          "Newest listings is very concise and displays the most important information",
          "Date when listing was posted is a nice touch to deduce if the poster is inactive",
          "Listing doesn’t show exact location address or phone numbers - could be helpful to ensure privacy",
          "Adding new item to inventory automatically is a very helpful idea",
        ],
        improvements: [
          "Flow to the chat message functionality could be clearer",
          "Should have a section to distinguish your listing versus the community’s listing",
          "Some of the textboxes/buttons may be too small for easy use on a mobile phone",
          "Creation of listing could use existing inventory from other parts of the app",
          "Could add image sending to the chat function or preformed responses",
        ]
      },
      {
        task: '2',
        path: '/images/angella-wireframe-2.png',
        link: 'https://balsamiq.cloud/snnaqus/py1c4gk/rB2AB',
        strengths: [
          "Key fields are shown when creating an item",
          "Able to sort and filter the inventory by key attribute",
          "Useful information and insight shown for an ingredient",
          "Recommendations are concise and placed strategically",
          "\"Select a metric\" makes sense for quantity",
          "Ingredient tab looks very balanced and clean"
        ],
        improvements: [
          "Inventory List page could show a summary of details for an item (i.e. quantity, expiry date) instead of just the item’s name",
          "Perhaps could streamline by having a set of an option when adding an item rather than allowing the user to input details of the item manually",
          "Reminder for expiring food might be too annoying/intrusive",
          "Add ingredient button could be floating instead of forced at the top for if inventory lists get long",
          "Could also add filter by expiry warning tag",
          "Insights unclear as to what it does - would this be something like past usage, this item often expires, you often eat a lot of this, etc?"
        ]
      }
    ],
  },
  {
    name: 'Zhou, James Wang',
    img: 'images/james.jpeg',
    screenshots: [
      {
        task: '1',
        path: '/images/james-wireframe-1.png',
        link: 'https://balsamiq.cloud/s3yyyb0/pes39be/r2278',
        strengths: [
          "The design is clean and simple to use",
          "It is aesthetic how each listing is displayed as a card",
          "Shows a summary of key fields for items on the homepage",
          "Users are able to search for a key item specified",
          "Has tabs to quickly access menu items such as home, listings and messages",
          "Messages tab looks clean and simple detailing exactly what the messaging is about",
        ],
        improvements: [
          "The \"Home\" tab might seem like it\'s referring to the home page of the app instead of the home page of the listing feature",
          "The \"Listings\" tab at the bottom correspond to the user\'s own listing, which might not be clear enough",
          "Could have a consistent design between the cards in the \"Home\" page and \"Listings\" page",
          "Should distinguish between messages to people that messaged you and people that you message to",
          "Option to look at your past listings as a small button would be interesting",
          "Quantity offering gets awkward between 1 carrot and 1g of beef - could universally do approximately in grams",
        ]
      },
      {
        task: '2',
        path: '/images/james-wireframe-2.png',
        link: 'https://balsamiq.cloud/s3yyyb0/pes39be/r5F26',
        strengths: [
          "If the user doesn\'t have anything planned for a certain meal, there is a button there to conveniently allow users to search for meals",
          "Suggests recipes and allows users to search up for specific recipes",
          "Users are able to customise portion sizes",
          "Users are able to favourite meals",
          "Shopping for x days and the app adjusting based on user input is very interesting",
        ],
        improvements: [
          "Maybe each recipe can highlight what ingredients the user already has in their inventory",
          "Could also have filter and sort functions for the meal listings",
          "Should show a way for the user to add the recipe to their meal plan directly from the recipe page",
          "If a favourite system exists, the wireframe could show how users can access their favourites",
          "Users should be able to mark off items in their shopping cart",
          "Should have a clear flow on how to differentiate showing recipes to the shopping list",
          "Doesn\'t specify what a portion means? User would need to assume for 1 person? Or for a family?",
        ]
      }
    ],
  },
  {
    name: 'Wu, Alex Yuxuan',
    img: 'images/alex.png',
    screenshots: [
      {
        task: '1',
        path: '/images/alex-wireframe-1.png',
        link: 'https://balsamiq.cloud/s927o7r/plf5dwa/r14DF',
        strengths: [
          "The use of statistics on the front page to motivate users in putting up ingredients as FFA and reduce food waste",
          "Shows if a particular user has other listings",
          "Shows alternative listings",
          "Shows suggested recipes for the item in the listing",
          "Doesn\'t overwhelm the user and allows them to pick between \'Share\' and \'Find\'",
          "Shows a visual map on the nearest ingredients and a table of the necessary information on the ingredient",
          "Quick action buttons to call, message and view location easily accessible on the listing page",
        ],
        improvements: [
          "For the “Add Ingredient” page, the option to add existing ingredient or add new ingredient should be on separate pages instead of same page, making it look confusing",
          "The listings in table view might seem a unclean and complicated to look at",
          "Some texts looked like links",
          "Graph on homepage may not be necessary - currently it is placed front and centre and may not provide too much value to the user",
          "Homepage is not intuitive; it is not clear at first glance where a user should go and what they should do",
          "Could have a section to distinguish the user\'s listings to the community",
        ]
      },
      {
        task: '2',
        path: '/images/alex-wireframe-2.png',
        link: 'https://balsamiq.cloud/s927o7r/plf5dwa/rF999',
        strengths: [
          "Quickly displays which items are closest to expiry",
          "Shows key information on the inventory page",
        ],
        improvements: [
          "Recommended recipes currently only show three, there should be an option to view more",
          "Recipe search bar is a bit small and positioned weirdly",
          "Listing the ingredients in table views make it look cluttered",
          "Not sure how to go from screen 2 to screen 3",
          "Placing the search bar at the bottom may confuse many users",
          "Doesn\'t have a \'Add Ingredient\' screen",
          "Wireframe flow is unclear on the objective of user task 2",
        ]
      }
    ],
  },
  {
    name: 'Ting, Gabriel Angelo',
    img: 'images/gabriel.jpeg',
    screenshots: [
      {
        task: '2',
        path: '/images/gabriel-wireframe-1.png',
        link: 'https://balsamiq.cloud/sy3fctf/p8ashkc/r1695',
        strengths: [
          "Home screen provides quick access to all the important areas of the app",
          "Screen for adding items is very concise",
          "Ingredient list clearly has expiry dates and quantities in different metrics",
        ],
        improvements: [
          "Showing ingredients in a table can look a bit unclean and cluttered",
          "The box for the quantity of item seems unnecessarily large",
          "Stock type and quantity could be put on the same line for clarity",
          "Navigation between different parts of the app (recipes, inventory) isn\'t clear",
          "“Add item” button could be floating when the user scrolls down the list",
          "Add item could have recommended or most added for common items such as eggs or milk",
          "Shopping cart text in the button is a little too close to the edges of the box",
        ]
      },
      {
        task: '3',
        path: '/images/gabriel-wireframe-2.png',
        link: 'https://balsamiq.cloud/sy3fctf/p8ashkc/r1695',
        strengths: [
          "Easy to add ingredients to shopping cart",
          "Easy to generate a shopping list based on recipes",
          "Recipes pages are very clean and easily allow for portion size adjustment",
          "Shopping list is very simple",
          "Simple description of each recipe is good",
          "Clear articulation of portion size and how many people it would be appropriate for and toggle for different sizings",
        ],
        improvements: [
          "Might it be redundant to be able to both add ingredients to a shopping cart and generate a shopping list based on recipes because it might introduce double-ups?",
          "Need option to remove or edit items on the shopping list",
          "Need option to remove or edit items on the shopping list",
          "No clear way to get to the instructions of the recipe beyond the description? Unless the steps are within the description",
          "Generating a shopping list for all the recipes is a nice touch but unclear whether it would overwrite everything currently in the shopping list - would it generate a temporary and then you could add it to your existing list?",
        ]
      }
    ],
  },
]

const prototypes = [
  {
    name: 'Pham, Mai Ngoc',
    img: 'images/angella.png',
    screenshot: '/images/angella-prototype.png',
    link: 'https://www.figma.com/proto/jdwhltBXfTR9Rl2hARMgxA/CS3240-Individual-Interactive-Prototype?page-id=0%3A1&type=design&node-id=1-2&viewport=303%2C353%2C0.31&t=EjemxO8nNziOI8zl-1&scaling=scale-down&starting-point-node-id=1%3A2&mode=design',
    strengths: [
      {
        title: 'Progressive disclosure',
        description: 'The interface shows the information in little bits. For example, each feature is abstracted out into its own page and the home screen and navbar have buttons to navigate to each feature. Another example is the inventory list, where each item is shown with only its name, quantity and expiry date. If the user wants to know more about a particular item, then they can click onto it.',
      },
      {
        title: 'Easy navigation',
        description: 'It is easy to complete the different steps involved in managing an inventory. A user can go back to the previous page without any problems.',
      },
      {
        title: 'Good colour scheme',
        description: 'The colour scheme is friendly and inviting, making people feel happy and wanting to reduce food waste. There is also a good colour contrast – everything is easily readable.',
      },
      {
        title: 'Autocomplete item name',
        description: 'When creating an item, the user gets provided with autocomplete options of an item name as they type. This prevents users from making mistakes.',
      },  
      {
        title: 'Information is grouped',
        description: 'Pieces of information are sectioned into boxes, making it digestible for the users. There is also not an overwhelming amount of information.',
      },
    ],
    improvements: [
      {
        title: 'Inconsistent graphics types',
        severity: 'Cosmetic',
        description: 'There are 2D vectors, 3D vectors and real-life images used as graphics for the app. The inconsistency in the graphics types prevents the app from having a strong brand identity.',
      },
      {
        title: 'Some small text sizes (Minor)',
        severity: 'Minor',
        description: 'The description text of a suggested recipe for an ingredient might be a bit too small to read. The clickable text at the top of the page to allow users to go back to the previous page is also too small.',
      },
      {
        title: 'Some clickable-looking elements aren’t implemented (Minor)',
        severity: 'Minor',
        description: 'Some elements, such as the filter button, sort button, search bar, form fields, user profile,... aren’t implemented in the interactive prototype so it is hard to tell how they would potentially work, making it hard to evaluate. Though, the main features are implemented.',
      },
      {
        title: 'Unable to undo create item (Major)',
        severity: 'Major',
        description: 'If a user were to accidentally or incorrectly create an item, They cannot delete it, but can only mark them as consumed or wasted. This is quite inconvenient if the user cares about their statistics.',
      },
    ]
  },
  {
    name: 'Zhou, James Wang',
    img: 'images/james.jpeg',
    screenshot: '/images/james-prototype.png',
    link: 'https://www.figma.com/proto/ZDexIsBn494CSptYZam3wV/CS3241-G2?type=design&node-id=24-92&t=LO60cZyEkMsLoSIN-1&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A2&mode=design',
    strengths: [
      {
        title: 'Intuitive and simple navigation',
        description: 'The interface is easy to navigate and feels natural to use. Someone using the app for the first time would experience little difficulty.',
      },
      {
        title: 'Clean interface',
        description: 'There are no extraneous buttons and the purpose of each button feels clear. Buttons are reasonably sized and placement is straightforward.',
      },
      {
        title: 'Appealing welcome visual',
        description: 'The visual presented on the home screen welcoming the user reviewed was noticed and appreciated.',
      },
    ],
    improvements: [
      {
        title: 'Boring and unimaginative',
        severity: 'Cosmetic',
        description: 'The interface feels similar to many existing apps. As such, the design does not feel fresh/innovative.',
      },
      {
        title: 'Too much bolded text/icons',
        severity: 'Minor',
        description: 'Bolded text/icons are used throughout the app, causing nothing to stand out particularly and making it a bit straining on the eyes.',
      },
      {
        title: 'Lack of colour',
        severity: 'Minor',
        description: 'The overall colour scheme of the app was described as “monochrome” and made limited use of coloured elements. Adding colours in certain spots on the app could help make the app feel more likely and friendly to the user.',
      },
      {
        title: 'Too easy to create a listing',
        severity: 'Minor',
        description: 'There is no confirmation prompt to create a listing, which may cause people to “accidentally” create listings.',
      },
      {
        title: 'Distracting insight visuals',
        severity: 'Major',
        description: 'The backgrounds used for “number of items claimed” and “number of items received” do not add much value and are somewhat distracting. Perhaps choosing a different visual or designing these elements differently would bring more attention.',
      },
    ]
  },
  {
    name: 'Wu, Alex Yuxuan',
    img: 'images/alex.png',
    screenshot: '/images/alex-prototype.png',
    link: 'https://www.figma.com/proto/XHnNZjHbQ0QeU8v2eNe2qK/CS3240-Individual-Interactive?type=design&node-id=9-247&t=Iu0oiSSILwl1ABlG-1&scaling=scale-down&page-id=0%3A1&starting-point-node-id=9%3A247&mode=design',
    strengths: [
      {
        title: 'Simple and fits with previous use of lists on mobile',
        description: 'Interface is clean and simple to use. Looks very much like previous list apps and data entry forms, fits the preconceived notion of what they are used to seeing.',
      },
      {
        title: 'Neutral colour scheme',
        description: 'Colours are easy to look at.',
      },
      {
        title: 'Intuitive interface',
        description: 'The map is helpful to see where you are relative to people and items nearby.',
      },
      {
        title: 'Margin of error for posters',
        description: 'Alternative source for an ingredient if the poster forgot to take down despite giving the ingredient away.',
      },
      {
        title: 'Clear control flow',
        description: 'Easy to navigate between options.',
      },
      {
        title: 'Formatting',
        description: 'Information is clear and easy to read.',
      },
    ],
    improvements: [
      {
        title: 'Unsure of purpose for the graph',
        severity: 'Cosmetic',
        description: 'Graph on home page a little simplistic. Visually this is meant to be relatively simple but it might not convey enough information as an ‘interesting statistic’ to be useful to the audience.',
      },
      {
        title: 'Dark mode',
        severity: 'Cosmetic',
        description: 'Current layout may be too bright for certain users who prefer darker overall theme as opposed to light pastels',
      },
      {
        title: 'Small clickable area in certain cases',
        severity: 'Minor',
        description: 'Top left back buttons are a little too small to comfortably read and press. As a result, the area doesn’t look as clickable as it should.',
      },
      {
        title: 'Cramped spacing',
        severity: 'Minor',
        description: 'Scroll information section in the map section is small and could display more entries in comparison to the map view which is quite large.',
      },
      {
        title: 'Lack of undo function',
        severity: 'Minor',
        description: 'Doesn’t look like deleting an entry or using the ‘shared’ button has an undo function as there is no example given for that part. In future iterations may also add this feature in the demonstration.',
      },
      {
        title: 'Unclear what some buttons do',
        severity: 'Major',
        description: '\"Share\" and \"Find\" were unintuitive before clicking on the buttons for the first time but made sense as soon as they tried to press the respective buttons. \"Share\" at least made some sense in context, but \"Find\" was misconstrued as finding people to share with as opposed to the intended finding ingredients.',
      },
      {
        title: 'Unimplemented clickable icons',
        severity: 'Major',
        description: 'Clicking on the location markers in the map portion should lead to a separate page on the ingredient that it portrays. This was missed in development.',
      },
    ]
  },
  {
    name: 'Ting, Gabriel Angelo',
    img: 'images/gabriel.jpeg',
    screenshot: '/images/gabriel-prototype.png',
    link: 'https://www.figma.com/proto/2zUURGyR6ReEgzQ6c5BWFw/cs3240-G2---Individual-Interactive-Prototype?type=design&node-id=71-1162&t=pUzfzpGLHyW9I1jV-1&scaling=scale-down&page-id=55%3A440&starting-point-node-id=71%3A1162&mode=design',
    strengths: [
      {
        title: 'User-centred',
        description: 'The user noted that the design of the interface was straightforward and tailored to their needs. They were able to quickly view recipes on the main page and noted how only important information is shown on screen. Content of the interface did not overwhelm the user.',
      },
      {
        title: 'Feedback loop',
        description: 'The user likes their is a structured process from finding recipes to generating a shopping list. They noted how it was quick to add recipes into their cart and generate a shopping list from it. They can see this feedback loop being part of their weekly shopping routine where before they go shopping, they add recipes to their shopping cart. During shopping, they can mark off items. After their shopping is completed, they can mark the shopping list as completed.',
      },
      {
        title: 'Simple interface',
        description: ' The user noted how the user interface was easy to use as a first time user. They noted that common functionality such as creating recipes, editing recipes or adding items to the shopping list was not cluttered and had their own respective screen space. This design played into the user’s heuristics where this design’s functionalities match to what you would find with other systems. For example, creating recipes is achieved by clicking on the ‘+’ button.',
      },
    ],
    improvements: [
      {
        title: 'Colour palette and contrast',
        severity: 'Cosmetic',
        description: 'One area of improvement is the colour contrast of action buttons. It hinders the app\'s aesthetics where most of the colour palette focuses on white/grey with a pastel shade. Meanwhile, the user notes that some action buttons do not exactly match the mood and ambience that the rest of the app is portraying. Improvements can be made to the final prototype by keeping in mind the colour consistency between UI elements.',
      },
      {
        title: 'Visual design of inputs',
        severity: 'Minor',
        description: 'Throughout the user testing session, it was not obvious that some input boxes with placeholder text labels were input fields to begin with. The placeholder label text seemed to confuse the user that it was a field that the user could have filled in. The design could be improved by having text labels right on top of the input fields rather than using placeholder text to direct the user for input.',
      },
      {
        title: 'Confusion between user recipes',
        severity: 'Major',
        description: 'The user notes that the interface that shows the recipes listing between custom and default recipes were unclear. The user was unsure whether he could not edit other recipes on the page. The purpose behind the app is to suggest recommended recipes to the user whilst also allowing them to create their own. To improve on the interface, user recipes could be grouped separately on a different tab content. A clear way to distinguish between the two types of recipes should be made. To implement it, the app could default showing suggested recipes only and have tabs such as \'All\' and \'Your Recipes\' to make it clear that there are different types.',
      },
      {
        title: 'Confusion between shopping cart state',
        severity: 'Major',
        description: 'It is unclear to the user on what state changes clicking \'Add to Shopping List\' button on the Recipe Cart Page does to the shopping list. User was confused whether it simply overrides the shopping list based on these recipes or if it adds it on top',
      },
    ]
  },
]

const allBlogs = [
  {
    id: 1,
    title: "User A",
    source: "user-A",
    date: "30 October, 2023",
    author: "Wu, Alex Yuxuan",
    category: "Reviews",
    pfp: "/images/alex.png"
  },
  {
    id: 2,
    title: "User B",
    source: "user-B",
    filesource: "../../blogs/ting-gabriel-angelo.md",
    date: "30 October, 2023",
    author: "Ting, Gabriel Angelo",
    category: "Business",
    pfp: "/images/gabriel.jpeg"
  },
  {
    id: 3,
    title: "User C",
    source: "user-C",
    date: "29 October, 2023",
    author: "Wu, Alex Yuxuan",
    category: "Business",
    pfp: "/images/alex.png"
  },
  {
    id: 4,
    title: "User D",
    source: "user-D",
    date: "30 October, 2023",
    author: "Pham, Mai Ngoc",
    category: "Business",
    pfp: "/images/angella.png"
  },
  {
    id: 5,
    title: "User E",
    source: "user-E",
    date: "31 October, 2023",
    author: "Zhou, James Wang",
    category: "Tutorial",
    pfp: "/images/james.jpeg"
  },
];

function Prototyping() {

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const getSeverityStyle = (severity) => {
    switch (severity) {
      case 'Cosmetic':
        return {
          padding: '0px 16px',
          borderRadius: '30px',
          fontSize: '0.9rem',
          backgroundColor: '#fae4a7',
        }
      case 'Minor':
        return {
          padding: '0px 16px',
          borderRadius: '30px',
          fontSize: '0.9rem',
          backgroundColor: '#fad0a7',
        }
      case 'Major':
        return {
          padding: '0px 16px',
          borderRadius: '30px',
          fontSize: '0.9rem',
          backgroundColor: '#fab3a7',
        }
      default:
        return null;
    }
  }

  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="🍒 Design & Prototyping" />
        <h2>Why is design and prototyping important?</h2>
        <p>Design and prototyping play a vital role in helping us <mark>visualise our ideas and make them easier to communicate</mark>. They enable us to <mark>test and refine our concepts quickly</mark>, saving time and resources by spotting problems early. Most importantly, by gathering feedback at each prototyping stage, we <mark>prioritise the user's needs and preferences</mark>, resulting in more user-centred products and solutions.</p>
        <h2 style={{ marginTop: "40px" }}>What were the steps involved in design and prototyping?</h2>
        <h3 style={{ marginTop: "26px" }}>1. Create personas, scenarios and storyboards</h3>
        <p>When creating a persona, it makes it <mark>easier to empathise with and understand the target users’ needs and motivation</mark>, allowing designers to create tailored interfaces for those users. </p>
        <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          {personas.map(persona => {
            return <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
                  <div style={{ width: "24px", height: "24px", bottom: '3px', position: 'relative' }}>
                    <img src={persona.image} alt="User image" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "100%" }}/>
                  </div>
                  <h4 style={{ margin: '10px' }}>{persona.name} – {persona.category} </h4>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                <div>
                  <h4 style={{ marginTop: '0' }}>Persona</h4>
                  <div style={{ width: "100%", backgroundColor: "#F9F9F9", padding: "20px", borderRadius: "10px", flexGrow: '1' }}>
                    {persona.description}
                  </div>
                </div>
                <div>
                  <h4 style={{ marginTop: '0' }}>Scenario</h4>
                  <div style={{ width: "100%", backgroundColor: "#F9F9F9", padding: "20px", borderRadius: "10px", flexGrow: '1' }}>
                    {persona.scenario.map(para => {
                      return <p>{para}</p>
                    })}
                  </div>
                </div>
                <div>
                  <h4 style={{ marginTop: '0' }}>Storyboard</h4>
                  <img src={persona.storyboard}/>
                </div>
              </AccordionDetails>
            </Accordion>
          })}
        </div>
        <h3 style={{ marginTop: "26px" }}>2. Conduct a rapid prototyping session</h3>
        <p>In our week 6 lecture, we participated in rapid prototyping, including the Crazy-8 method. This approach motivated us to generate a diverse range of ideas quickly, encouraging both creativity and innovation.</p>
        
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" sx={{ width: "100%" }}>
          <Grid item xs={12} md={6}>
            <Accordion defaultExpanded={true} disabled={true} sx={{ backgroundColor: "white !important", width: "100% !important" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ opacity: "100% !important" }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
                  <div style={{ width: "24px", height: "24px", bottom: '3px', position: 'relative' }}>
                    <img src="/images/angella.png" alt="User image" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "100%" }}/>
                  </div>
                  <h4 style={{ margin: '10px' }}>Pham, Mai Ngoc</h4>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                <img src="/images/angella-crazy8.png" style={{ borderRadius: "8px" }}/>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <Accordion defaultExpanded={true} disabled={true} sx={{ backgroundColor: "white !important" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ opacity: "100% !important" }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
                  <div style={{ width: "24px", height: "24px", bottom: '3px', position: 'relative' }}>
                    <img src="/images/james.jpeg" alt="User image" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "100%" }}/>
                  </div>
                  <h4 style={{ margin: '10px' }}>Zhou, James Wang</h4>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              <img src="/images/james-crazy8.png" style={{ borderRadius: "8px" }}/>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <Accordion defaultExpanded={true} disabled={true} sx={{ backgroundColor: "white !important" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ opacity: "100% !important" }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
                  <div style={{ width: "24px", height: "24px", bottom: '3px', position: 'relative' }}>
                    <img src="/images/alex.png" alt="User image" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "100%" }}/>
                  </div>
                  <h4 style={{ margin: '10px' }}>Wu, Alex Yuxuan</h4>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              <img src="/images/alex-crazy8.png" style={{ borderRadius: "8px" }}/>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <Accordion defaultExpanded={true} disabled={true} sx={{ backgroundColor: "white !important" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ opacity: "100% !important" }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
                  <div style={{ width: "24px", height: "24px", bottom: '3px', position: 'relative' }}>
                    <img src="/images/gabriel.jpeg" alt="User image" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "100%" }}/>
                  </div>
                  <h4 style={{ margin: '10px' }}>Ting, Gabriel Angelo</h4>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              <img src="/images/gabriel-crazy8.png" style={{ borderRadius: "8px" }}/>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <h3 style={{ marginTop: "26px" }}>3. Draft up low-fidelity wireframes</h3>
        <p>Next, we evenly distributed two important user tasks among the team to develop low-fidelity wireframes for. We chose Balsamiq for its simplicity, as its basic components allowed us to <mark>concentrate on showcasing the app's functionality</mark> and ease of use rather than its aesthetics. Wireframes helped us <mark>rapidly share the basic structure of our interfaces</mark>, like page layout, navigation, and content hierarchy.</p>
        <p>After finishing our separate wireframes, we shared feedback, <mark>highlighting the strengths and areas for improvement</mark> in each design.</p>
        
        <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          {wireframes.map(member => {
            return <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
                  <div style={{ width: "24px", height: "24px", bottom: '3px', position: 'relative' }}>
                    <img src={member.img} alt="User image" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "100%" }}/>
                  </div>
                  <h4 style={{ margin: '10px' }}>{member.name}</h4>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
                <a href={member.screenshots[0].link} target='_blank' style={{ textDecoration: "none" }}>
                  <Alert severity="info" sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>Click here to view {member.name}'s individual wireframes on Balsamiq!</Alert>
                </a>
                
                {member.screenshots.map(screenshot => {
                  return <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <div>
                      <h4 style={{ margin: '10px' }}>User task {screenshot.task}</h4>
                      <img src={screenshot.path}/>
                    </div>
                    <div className="feedback-container" style={{ display: 'flex', flexDirection: 'row', gap: '16px', width: '100%', marginTop: '10px' }}>
                      <div className="feedback-subcontainer" style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Alert severity="success" sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>What was done well?</Alert>
                        <div style={{ backgroundColor: '#F9F9F9', borderRadius: '4px', padding: '18px 24px 18px 16px', height: '100%' }}>
                          <ul>
                            {screenshot.strengths.map(strength => {
                              return <li>{strength}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="feedback-subcontainer" style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Alert severity="warning" sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>What to improve on?</Alert>
                        <div style={{ backgroundColor: '#F9F9F9', borderRadius: '4px', padding: '18px 24px 18px 16px', height: '100%' }}>
                          <ul>
                            {screenshot.improvements.map(improvement => {
                              return <li>{improvement}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                })}
              </AccordionDetails>
            </Accordion>
          })}
        </div>

        <h3 style={{ marginTop: "26px" }}>4. Develop individiual high-fidelity interactive prototypes</h3>
        <p>After incorporating feedback from the wireframe stage, each team member focused on a key user task and developed a high-fidelity prototype using Figma. The high-fidelity prototype is crucial because it allows for a <mark>more detailed and realistic representation of the user interface</mark>, aiding in better evaluation, visualisation, and understanding.</p>
        <p>Next, we shared these prototypes with our intended users to collect extra input and enhance the design based on real user interactions. Each of us reached out to one or two target users, seeking feedback through a <mark>heuristic evaluation</mark> (using the template given in Lecture 8). The team member then had a chance to get the user to talk aloud about their experience using the prototype, ask the user for general impressions, and clarify any ambiguous feedback provided on the evaluation form. The feedback was then compiled and summarised below, with severity ratings assigned to areas for improvement.</p>

        <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          {prototypes.map(prototype => {
            return <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
                  <div style={{ width: "24px", height: "24px", bottom: '3px', position: 'relative' }}>
                    <img src={prototype.img} alt="User image" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "100%" }}/>
                  </div>
                  <h4 style={{ margin: '10px' }}>{prototype.name}</h4>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
                <a href={prototype.link} target='_blank' style={{ textDecoration: "none" }}>
                  <Alert severity="info" sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>Click here to view {prototype.name}'s individual interactive prototype on Figma!</Alert>
                </a>
                <img src={prototype.screenshot} alt="High-fidelity prototype" style={{ borderRadius: "4px" }}/>
                <div className="feedback-container" style={{ display: 'flex', flexDirection: 'row', gap: '16px', width: '100%', marginTop: '10px' }}>
                  <div className="feedback-subcontainer" style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Alert severity="success" sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>What was done well?</Alert>
                    <div style={{ backgroundColor: '#F9F9F9', borderRadius: '4px', padding: '26px', height: '100%' }}>
                      {prototype.strengths.map(strength => {
                        return <span>
                          <h6 style={{ margin: "0 0 10px 0" }}>{strength.title}</h6>
                          <p>{strength.description}</p>
                        </span>;
                      })}
                    </div>
                  </div>
                  <div className="feedback-subcontainer" style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Alert severity="warning" sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>What to improve on?</Alert>
                    <div style={{ backgroundColor: '#F9F9F9', borderRadius: '4px', padding: '26px', height: '100%' }}>
                      {prototype.improvements.map(improvement => {
                        return <span>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '8px' }}>
                            <h6 style={{ margin: "0" }}>{improvement.title}</h6>
                            <div style={getSeverityStyle(improvement.severity)}>{improvement.severity}</div>
                          </div>
                          <p>{improvement.description}</p>
                        </span>;
                      })}
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          })}
        </div>

        <h3 style={{ marginTop: "26px" }}>5. Refine and combine into one group high-fidelity interactive prototype</h3>
        <a href='https://www.figma.com/proto/6wP47KgGmxrGG1gA4t2THF/G2---Group-Interactive-Prototype?page-id=0%3A1&type=design&node-id=7-423&viewport=302%2C818%2C0.22&t=pPx01a3ReGRhcH68-1&scaling=scale-down&starting-point-node-id=7%3A423&mode=design' target='_blank' style={{ textDecoration: "none" }}>
          <Alert severity='info' sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center', marginBottom: "16px" }}>Click here to view the group interactive prototype on Figma!</Alert>
        </a>
        <p>After considering the feedback on our separate prototypes, we merged them into a single prototype showcasing Reducr's features cohesively. This step posed challenges, as <mark>we had varied design ideas</mark>, such as the navbar appearance and color scheme. <mark>We collaborated to discuss and find compromises</mark>, making necessary adjustments.</p>
        <p>For instance, we chose Mai Ngoc's Karla sans-serif font for its clean appearance. The app's color scheme includes a seasalt grey background (#F7F7F7), onyx black for texts (#404040), and olivine green (#B4C88C) as the main accent color for buttons. To soften the app's appearance, we set a 10-pixel border radius for elements, aiming for a more inviting look. We <mark>focused on maintaining a cohesive design across elements to strengthen the app's brand identity</mark> and enhance its overall aesthetic. However, upon reflection, we realised that some accent colors we initially considered didn't align with the app's aesthetic. As a result, we made adjustments in the final interactive prototype to ensure a harmonious visual experience.</p>
        <p>Then carried out <mark>five user assessments</mark> to identify areas for improvement. During these evaluations, we interviewed users from our target groups, <mark>assigning them tasks to complete</mark>. We observed their actions, noted the steps they took, and paid attention to their emotions and body language. Additionally, we asked questions during the interviews to delve deeper into their thought processes. All these user evaluations were conducted one-on-one to ensure users felt at ease expressing their genuine thoughts about Reducr.</p>
        <p style={{ marginBottom: "30px" }}>The notes and findings from our user evaluations are accessible through the following blogs.</p>

        {window.matchMedia('(min-width: 768px)').matches && <div className="row blog-wrapper" style={{ gridRowGap: "24px" }}>
          {allBlogs.map((blogItem) => (
            <div className="col-md-6" key={blogItem.id}>
              <Blog blogData={blogItem}/>
            </div>
          ))}
        </div>}
        {window.matchMedia('(max-width: 768px)').matches && <div className="row blog-wrapper" style={{ gridRowGap: "10px", padding: "0" }}>
          {allBlogs.map((blogItem) => (
            <div className="col-md-6" key={blogItem.id}>
              <Blog blogData={blogItem}/>
            </div>
          ))}
        </div>}
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
        </div>
      </div>
    </section>
  );
}

export default Prototyping;
