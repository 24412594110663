import InfoIcon from '@mui/icons-material/Info';
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";
import { Alert } from '@mui/material';

const filters = [
  {
    id: 1,
    text: "Home",
  },
  {
    id: 2,
    text: "Inventory",
  },
  {
    id: 3,
    text: "Recipes",
  },
  {
    id: 4,
    text: "Free-For-All",
  },
  {
    id: 5,
    text: "Shopping Lists",
  },
];

const allData = [
  {
    id: 1,
    category: "home",
    title: "Home & Profile",
    images: [
      "/images/home.png",
      "/images/profile-stats.png",
      "/images/profile-edit.png"
    ],
    texts: [
      {
        subtitle: "Home – inviting and aesthetic",
        description: [
          "The home page is intentionally kept simple and uncluttered. It features inviting images and graphics designed to encourage users to explore the app's features. Navigation is user-friendly, allowing easy access to every section of the app directly from the homepage.",
        ]
      },
      {
        subtitle: "Profile – incentivising and informative",
        description: [
          "Inside a user's profile, there are badges earned by analysing their activities. For instance, reducing weekly food waste percentage over a specific period or making eco-friendly shopping choices can earn users badges. The experience/level/badge system serves as an incentive for users to decrease their personal food waste and contribute to environmental conservation. In the future, Reducr may explore partnerships with companies, offering discount coupons to long-term users who successfully save a significant amount of food.",
          "Additionally, the profile page displays weekly statistics on user consumption and waste. This helps users make smarter grocery shopping choices, like deciding whether to buy more or less of a particular item. Future updates may include additional stats like cost, CO2 emissions, water and land usage for wasted food. The page will also highlight positive contributions, such as the amount of food a user has shared with the community through the Free-For-All feature.",
          "Furthermore, as a design precaution, the navbar is eliminated on the edit profile page to prevent users from unintentionally navigating away when they have unsaved changes."
        ]
      },
    ]
  },
  {
    id: 2,
    category: "inventory",
    title: "Inventory",
    images: [
      "/images/inventory.png",
      "/images/inventory-add-item.png",
      "/images/inventory-view-item.png",
      "/images/inventory-edit-item.png",
    ],
    texts: [
      {
        subtitle: "Inventory – simple but flexible",
        description: [
          "The inventory page displays different item types, presenting their total quantity and earliest expiry date. Users have the flexibility to create multiple entries for each item type, accommodating scenarios like having multiple milk cartons at home. Clicking on a specific item type allows users to view all associated entries. They can edit entry quantities and mark them as consumed or wasted. For instance, if there's a 1L milk entry, users can select the item and mark 0.5L as consumed or wasted.",
          "The main inventory page highlights items nearing expiry, and clicking on one redirects the user to the view item page. In addition to the aforementioned features, this page provides insights into the item, such as the user's weekly consumption or wastage. Users also receive suggested recipes for the item, facilitating easier meal planning and preparation.",
          "On the add item page, the search bar offers autocomplete suggestions based on the user's input. Clicking on a suggestion automatically selects default location and metric settings for the item. This streamlines the process, saving users from manually entering all the details while still allowing them to edit the provided default values if desired.",
        ],
      }
    ]
  },
  {
    id: 3,
    category: "recipes",
    title: "Recipes",
    images: [
      "/images/recipes.png",
      "/images/recipes-create.png",
      "/images/recipes-listing.png",
      "/images/recipes-add-to-shopping.png",
    ],
    texts: [
      {
        subtitle: "Recipes – diverse and convenient",
        description: [
          "Users can explore a varied range of recipes or search for specific ones. Recipes can be organised into popular categories using tabs below the search bar. Additionally, users have the option to filter or sort recipes according to their preferences.",
          "Clicking on a recipe reveals its description, ingredients, and steps. Users can adjust portion sizes, and ingredient quantities are conveniently updated. Additionally, users can add a recipe's ingredients to a personal shopping list, displaying all items and their total quantities for easy reference during grocery shopping. For instance, adding 'Chicken Curry' and 'Chicken Parmesan' recipes to the 'Grocery run' list, each requiring 250g and 300g of chicken, would show a total of 550g in the shopping cart for convenience. This feature prevents users from purchasing too little or too much of what they need at the grocery store.",
          "Additionally, users have the option to create their own recipes, providing them with increased flexibility within the app."
        ],
      }
    ]
  },
  {
    id: 4,
    category: "free-for-all",
    title: "Free-For-All",
    images: [
      "/images/ffa.png",
      "/images/ffa-view.png",
      "/images/ffa-msgs.png",
      "/images/ffa-own-listing.png",
      "/images/ffa-create.png",
    ],
    texts: [
      {
        subtitle: "Free-For-All – simple and helpful",
        description: [
          "The Free-For-All (FFA) feature enables users to post or claim unused food items from the community, aiming to reduce food waste by redistributing it locally. On the front page, users can see listings closest to them and easily search for specific items using the search bar. Each listing displays the name, expiry date, posted timestamp, and distance to the user, allowing quick evaluation of which items are worth claiming.",
          "When viewing an item, users can access the item's description, offering valuable details about any defects or reasons for giving it away. Clicking the map icon opens the user's map app, displaying the approximate location of the item for privacy reasons. At the bottom of the screen, a 'Send message' button allows users to contact the listing's poster. In the event of an accepted claim request, the poster can share their address through the messaging function.",
          "On the FFA front page, the two graphics at the top provide access to user listings and messages. In the listings page, users can create a new listing by clicking the green add button at the bottom right. Additionally, users have the option to edit or delete their listings. When choosing to delete a listing, a warning modal is prompted to prevent accidental deletions."
        ],
      }
    ]
  },
  {
    id: 5,
    category: "shopping lists",
    title: "Shopping Lists",
    images: [
      "/images/shopping.png",
      "/images/shopping-archived.png",
      "/images/shopping-view.png",
      "/images/shopping-add-modal.png",
    ],
    texts: [
      {
        subtitle: "Shopping lists – straight-forward",
        description: [
          "Based on user feedback, we've incorporated the ability to have multiple shopping lists. Users can easily access both active and archived shopping lists, providing enhanced awareness and control over their grocery shopping for more informed decisions. Clicking on a shopping list reveals the total quantity of each item needed, particularly beneficial when multiple recipes share common ingredients (linked to the recipes feature). Users can also include additional items unrelated to their recipes. When adding an item, they can either search for it (with an automatic default metric) or manually input the desired metric."
        ],
      }
    ]
  },
];

function Works() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.category === "home"));
  }, [getAllItems]);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData = getAllItems.filter(
      (data) =>
        data.category === e.target.textContent.toLowerCase() &&
        data.id <= dataVisibleCount
    );
    setVisibleItems(tempData);
  };

  return (
    <section id="final-product">
      <div className="container">
        <Pagetitle title="🥝 Final Interactive Prototype" />
        <a href='https://www.figma.com/proto/LtGg4j7UhjxtFrQfo66yWj/G3---Final-Interactive-Prototype?page-id=0%3A1&type=design&node-id=7-423&viewport=691%2C2745%2C0.51&t=EIlulyEaebB9cxAJ-1&scaling=scale-down&starting-point-node-id=7%3A423&mode=design' target='_blank' style={{ textDecoration: "none" }}>
          <Alert severity='info' sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center', marginBottom: "22px" }}>Click here to view the final interactive prototype on Figma!</Alert>
        </a>
        {/* Start Portfolio Filters */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters */}

        {/* Start Portfolio Items */}
        <div>
          {visibleItems.map((item) => (
            <Portfolio portfolio={item} />
          ))}
        </div>
        {/* End Portfolio Items */}
      </div>
    </section>
  );
}

export default Works;
