import React from "react";
import TrackVisibility from "react-on-screen";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContents = [
  {
    name: "Pham, Mai Ngoc",
    matricNo: "A0276753R",
    avatarImage: "/images/angella.png",
    content:
      "Hi! I am a 3rd-Year Computer Science exchange student from the University of New South Wales in Sydney, Australia. I have a passion for UI/UX designing and teaching. I've been a teaching assistant for multiple courses at my university, including a Frontend Web-Development course! In my spare time, I love to travel and capture my life through film photography. Through this module, I've learnt to create effective user-centric designs to solve real-world problems!",
    progressData: [
      {
        id: 1,
        title: "Development",
        percentage: 72,
        progressColor: "#FFD15C",
      },
      {
        id: 2,
        title: "UI/UX Design",
        percentage: 92,
        progressColor: "#FF4C60",
      },
      {
        id: 3,
        title: "Caffeinated",
        percentage: 88,
        progressColor: "#6C6CE5",
      },
    ],
  },
  {
    name: "Zhou, James Wang",
    matricNo: "A0277188L",
    avatarImage: "/images/james.jpeg",
    content:
      "Hi everyone! I am an exchanger from the University of Calgary in Canada. My interests lie at the intersection of business and technology, which includes data analytics, information systems, and software project management. In taking up CS3240, my goal is to learn the essential skills for creating functional, simple and aesthetic interfaces. Outside of school, I enjoy exploring new places and indulging in new cuisines. Feel free to hit me up for a chat!",
      progressData: [
        {
          id: 4,
          title: "Development",
          percentage: 90,
          progressColor: "#FFD15C",
        },
        {
          id: 5,
          title: "UI/UX Design",
          percentage: 62,
          progressColor: "#FF4C60",
        },
        {
          id: 6,
          title: "Procrastinator",
          percentage: 74,
          progressColor: "#6C6CE5",
        },
      ],
  },
  {
    name: "Wu, Alex Yuxuan",
    matricNo: "A0271132U",
    avatarImage: "/images/alex.png",
    content:
      "Hey! I am a 3rd-Year Actuarial/Computer Science exchange student from the University of New South Wales in Sydney, Australia. I have a passion for badminton, gaming and good food! When I'm not doing my assignments, you can find me perpertually playing in League of Legends and, unfortunately, horribly losing. I took up CS3240 in hope of refining my design skills and learn more about the process of creating a design solution from scratch.",
      progressData: [
        {
          id: 1,
          title: "Development",
          percentage: 89,
          progressColor: "#FFD15C",
        },
        {
          id: 2,
          title: "UI/UX Design",
          percentage: 67,
          progressColor: "#FF4C60",
        },
        {
          id: 3,
          title: "Win Rate",
          percentage: 34,
          progressColor: "#6C6CE5",
        },
      ],
  },
  {
    name: "Ting, Gabriel Angelo",
    matricNo: "A0277201M",
    avatarImage: "/images/gabriel.jpeg",
    content:
      "G'day mate! To introduce myself, I am an exchanger from the University of New South Wales in Sydney, Australia. I am currently in my 4th and last year of my Computer Science degree. I have had a lot of experiences with frontend development, such as directing student-led projects and interning at Canva! I hope to further refine my UI/UX design skills through this module. Please hit me up if you see me around, I'd love to talk about my travels!",
      progressData: [
        {
          id: 7,
          title: "Development",
          percentage: 92,
          progressColor: "#FFD15C",
        },
        {
          id: 8,
          title: "UI/UX Design",
          percentage: 82,
          progressColor: "#FF4C60",
        },
        {
          id: 9,
          title: "Traveller",
          percentage: 95,
          progressColor: "#6C6CE5",
        },
      ],
  },
]

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="🍉 The Team" />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          {aboutContents.map(aboutContent => {
            return <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="col-md-3">
                <div className="text-center text-md-left" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ height: '140px', width: '140px' }}>
                    <img src={aboutContent.avatarImage} alt={aboutContent.name} style={{ borderRadius: "100%", objectFit: 'cover', width: '100%', height: '100%' }}/>
                  </div>
                  <h4 style={{ margin: '18px 0px 6px 0px' }}>{aboutContent.name}</h4>
                  <h6 style={{ margin: '8px 0px 8px 0px', fontWeight: "normal"  }}>{aboutContent.matricNo}</h6>
                </div>
              </div>
              <div className="col-md-9 triangle-left-md triangle-top-sm">
                <div className="bg-white shadow-dark padding-30" style={{ borderRadius: '10px' }}>
                  <div className="row">
                    <div className="col-md-7">
                      <p style={{ margin: '0px' }}>{aboutContent.content}</p>
                    </div>
                    <div className="col-md-5">

                      {aboutContent.progressData.map(progress => (
                        <TrackVisibility
                          once
                          key={progress.id}
                          className="progress-wrapper"
                        >
                          <Skill progress={progress} />
                        </TrackVisibility>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </section>
  );
}

export default About;
