import React, { useState } from "react";

function Portfolio({ portfolio }) {
  const { category, images, texts } = portfolio;
  const [toggler, setToggler] = useState(false);

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px", padding: "32px", boxShadow: "0px 5px 20px 0px rgba(69, 67, 96, 0.1)", marginBottom: "20px", display: "flex", flexDirection: "column", gap: "36px" }}>
      <div style={{ display: "flex", gap: "20px" }}>
        {images.map(image => {
          return <div>
            <img src={image} style={{ width: "100%", objectFit: "cover", borderRadius: "10px" }}/>
          </div>
        })}
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {texts.map(text => {
          return <div>
            <h3 style={{ marginTop: "0", fontSize: "1.3rem" }}>{text.subtitle}</h3>
            {/* <div style={{ backgroundColor: "#F9F9F9", padding: "26px", borderRadius: "10px", display: "flex", flexDirection: "column", gap: "16px" }}> */}
              {text.description.map(para => {
                return <p>{para}</p>;
              })}
            {/* </div> */}
          </div>
        })}
      </div>
    </div>
  );
}

export default Portfolio;
