import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Card from "../elements/Card";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: '10px',
  boxShadow: '0px 5px 20px 0px rgba(69, 67, 96, 0.1)',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "20px",
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const userGroupsData = [
  {
    id: 1,
    icon: "images/books.jpg",
    title: "College students",
    content:
      "College students living on or near campuses might have limited budgets, access to communal kitchens, and variable schedules. The app could offer budget-friendly recipes and location-based alerts for discounted or free food items on campus.",
    color: "white",
    contentColor: "dark",
  },
  {
    id: 2,
    icon: "images/hands-and-heart.jpg",
    title: "Parents",
    content:
      "Parents often deal with grocery shopping, meal planning, and leftover management. The app could help them track food expiration dates, suggest recipes based on available ingredients, and provide tips on reducing food waste in family households.",
    color: "white",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/omelette-pan.jpg",
    title: "Cooking enthusiasts",
    content:
      "Cooking enthusiasts are more likely to regularly cook and experiment with different ingredients. They might have creative insights into ways to utilise food scraps and reduce food waste. They can share their knowledge with the community.",
    color: "white",
    contentColor: "dark",
  },
];

function UserResearch() {
  return (
    <section id="user-research">
      <div className="container">
        <Pagetitle title="🍊 User Research & Analysis"/>
        <h2>Why is user research important?</h2>
        <p>User research plays a crucial role in our design process. <mark>We aim to discover what users think about food waste</mark>, including why they waste food, if it's difficult to reduce food waste, and the reasons behind these challenges. This helps us design with the user in mind, leading to more user-friendly and effective solutions.</p>
        <h2 style={{ marginTop: "40px" }}>How was user research conducted?</h2>
        <h3 style={{ marginTop: "26px" }}>1. Identify target user groups</h3>
        <p>Identifying the target user groups helps us <mark>create solutions that match their unique needs and preferences</mark>, which lowers the risk of making something that doesn't appeal to anyone in particular. It also guides us in <mark>thinking about solutions from the user's perspective</mark>, ensuring a user-centric approach.</p>
        <p>We have thought of considering F&B businesses as a potential target user group. However, it would greatly enlarge the scope of our project. Thus, we wanted to <mark>focus on reducing food wastes in household settings</mark>.</p>
        <div className="row fix-spacing">
          {userGroupsData.map((userGroup) => (
            <div className="col-md-4" key={userGroup.id}>
              <Card service={userGroup}/>
            </div>
          ))}
        </div>
        <h3 style={{ marginTop: "30px" }}>2. Conduct contextual inquiries</h3>
        <p>Once we pinpointed our target user groups, each team member reached out to a person they know who falls into one of these groups. Our goal is to <mark>have one user representative from each target group to broaden our research</mark>. Conducting contextual inquiries is an ideal and vital approach for our problem because it's particularly useful when <mark>observing users and exploring their actions can reveal valuable, previously unknown information</mark>.</p>
        <p>During our contextual inquiries, we chose to use the <mark>master-apprenticeship approach</mark>. In this model, the user or interviewee takes on the role of the master, while the designer or interviewer becomes the apprentice. It enables the designer to closely watch the user and their decision-making in specific situations. The designer can then ask the user questions to gain a deeper understanding.</p>
        <p>We had two main contexts in which we could carry out our contextual inquiries, which are:</p>
        <div style={{ backgroundColor: "white", borderRadius: "10px", padding: "32px", boxShadow: "0px 5px 20px 0px rgba(69, 67, 96, 0.1)", marginBottom: "20px" }}>
          <b>Grocery shopping</b> is a valuable scenario for understanding real-life behaviors and choices related to food purchases, like brand and quantity selections. This directly relates to food waste reduction. It also helps us explore user planning habits for shopping and identify their needs and challenges, enabling us to create solutions that address these issues effectively.
        </div>
        <div style={{ backgroundColor: "white", borderRadius: "10px", padding: "32px", boxShadow: "0px 5px 20px 0px rgba(69, 67, 96, 0.1)", marginBottom: "20px" }}>
          Observing users <b>cooking in a kitchen setting</b> lets us understand their cooking habits, like controlling portion sizes, reusing leftovers, and getting creative with recipes, all of which affect reducing food waste. Additionally, it helps us identify the difficulties and problems users face when dealing with close-to-expiration foods.
        </div>
        Following our contextual inquiries, we gathered to collectively analyse the notes we'd recorded during the interviews.
        <h3 style={{ marginTop: "30px" }}>3. Analyse data</h3>
        <p>To analyse our data, we employed the <mark>affinity diagram method</mark>. This method entails categorising the notes into six primary groups: key observations, user statements, breakdowns, insights, design ideas, and questions and uncertainties. We randomly selected a note, then identified notes that shared a similar pattern. It's a bottom-up process where trends can be gradually grouped to <mark>identify broader patterns and insights</mark>.</p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h4 style={{ margin: '10px' }}>📊 Affinity diagram</h4>
          </AccordionSummary>
          <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
            <a href="https://www.figma.com/file/sOqwCjp9hrhhkeERSgGb8g/Reducr---User-Research%2C-Affinity-Diagram?type=whiteboard&node-id=0%3A1&t=3NMKDJz6KR0rM6BL-1" target="_blank" style={{ textDecoration: "none" }}>
              <Alert severity="info" sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>Click here to view the affinity diagram on FigmaJam!</Alert>
            </a>
            <img src="/images/affinity-diagram.png" alt="Affinity diagram for user research process" style={{ borderRadius: "10px" }}/>
          </AccordionDetails>
        </Accordion>
        <p style={{ marginTop: "18px" }}>From the affinity diagram, we identified a few key trends.</p>
        <h3 style={{ marginTop: "30px" }}>4. Identify key user tasks</h3>
        <div style={{ backgroundColor: "white", borderRadius: "10px", padding: "32px", boxShadow: "0px 5px 20px 0px rgba(69, 67, 96, 0.1)", marginBottom: "20px" }}>
          <h4 style={{ marginTop: '0' }}>👥 User task 1 – Putting unconsumed near-expiry food as free-for-all (FFA)</h4>
          <hr/>
          <p style={{ marginBottom: '0' }}>Users are able to make listings of uncooked items that they want to put up as FFA, if they know they won’t consume them by expiry date. Users can see listings that are within a certain radius from them. Users can interact with other users to claim listings or ask questions regarding the listings.</p>
        </div>
        <div style={{ backgroundColor: "white", borderRadius: "10px", padding: "32px", boxShadow: "0px 5px 20px 0px rgba(69, 67, 96, 0.1)", marginBottom: "20px" }}>
          <h4 style={{ marginTop: '0' }}>📦 User task 2 – Keeping track of food in one's inventory</h4>
          <hr/>
          <p style={{ marginBottom: '0' }}>Users can create an inventory of items they currently possess, complete with their respective expiry dates. When an item in the user's inventory approaches its expiry date, the app sends a notification as a reminder. Additionally, the app offers recipe suggestions based on the ingredients available in the user's inventory.</p>
        </div>
        <div style={{ backgroundColor: "white", borderRadius: "10px", padding: "32px", boxShadow: "0px 5px 20px 0px rgba(69, 67, 96, 0.1)", marginBottom: "20px" }}>
          <h4 style={{ marginTop: '0' }}>💡 User task 3 – Receiving recipe and shopping recommendations</h4>
          <hr/>
          <p style={{ marginBottom: '0' }}>Users receive personalised shopping recommendations that include ingredient lists and quantities tailored to their pantry's needs. They can contribute to the app by adding recipes and specifying their meal preferences for a given week. The recommended shopping list is generated based on the required ingredients for the week's meal plan and any missing items in the user's pantry. The app also offers precise ingredient quantities for the intended portion sizes. If desired, users can customise the shopping list to meet their specific preferences and requirements.</p>
        </div>
      </div>
    </section>
  );
}

export default UserResearch;
