import Markdown from "markdown-to-jsx";
import React, { useEffect, useState } from "react";

function BlogDetails(props) {
  const [content, setContent] = useState("");
  let blogFile;
  if (props.match.params.member) {
    blogFile = props.match.params.member;
  } else if (props.match.params.user) {
    blogFile = props.match.params.user;
  }

  useEffect(() => {
    import(`../blogs/${blogFile}.md`)
      .then((res) => res.default)
      .then((res) => {
        fetch(res)
          .then((result) => result.text())
          .then((result) => setContent(result));
      });
  }, [content, blogFile]);

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  // document.body.classList.add("dark");
  //Uncomment the above line if you use dark version

  return (
    <>
      <main className={toggleMenu ? "content open" : "content"} style={{ marginLeft: "0px" }}>
        <div className="blog-page-section">
          <div className="container">
            <div className="blog-single shadow-dark p-30">
              <Markdown>{content}</Markdown>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default BlogDetails;
